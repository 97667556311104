@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../assets/fonts/Nunito-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../assets/fonts/Outfit-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Outfit';
  src: url('../assets/fonts/Outfit-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

:root {
  --header-height: 6rem;
  --primary-color: #bbadeb;
  --secondary-color: #f2fea4;
  --dark-color: #4a6741;
  --light-color: #ebefe3;
  --body-font: "Nunito", sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 100;
}

body {
  position: relative;
  font-family: 'Nunito', sans-serif;
  font-size: var(--normal-font-size);
  transition: 0.5s;
  padding-left: 1rem;
  font-weight: 400;
}
.a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.app-container {
  margin-top: var(--header-height);
  padding: 24px;
  padding-left: calc(24px + var(--nav-width));
  padding-right: 24px;
  border-radius: 10px;
}

input,
textarea {
  background-color: transparent !important;
}

.l-navbar {
  width: 7rem;
  height: 81vh;
  background-color: rgba(245, 245, 245, 0.891);
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-decoration: none;
  box-shadow: none;
  border-radius: 0 0 5px 0;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 0 27px 27px 0;
}

.l-navbar-closed {
  left: -7rem;
}

.l-navbar .MuiListItem-root {
  padding: 18px;
  transition: background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.l-navbar .MuiListItem-root:hover {
  background-color: var(--light-color);
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.icon-title {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  width: 100%;
  line-height: 1.2;
  margin-top: 5px;
}

.icon-title.active {
  text-decoration: underline;
}

.nav-icon {
  font-size: 1.5rem;
  color: #101010;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.4rem;
  width: 100%;
  line-height: 1.2;
  margin-top: 5px;
}

.navbar {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
  background-color: #fcfcfc;
  z-index: var(--z-fixed);
  transition: 0.5s;
}

.header_toggle {
  color: var(--dark-colour);
  font-size: 1.8rem;
  cursor: pointer;
  padding-left: 5px;
}

/*.logo-bkoffice {*/
/*  color: var(--dark-colour);*/
/*  margin-left: auto;*/
/*  padding-left: 1rem;*/
/*  padding-right: 3rem;*/
/*  align-items: right;*/
/*  letter-spacing: 0.6rem;*/
/*  font-weight: 400;*/
/*  font-size: 1.8rem;*/
/*}*/

.pay-period {
  color: var(--dark-colour);
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
}

.payroll-period {
  color: #fcfcfc;
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
}

.form-container {
  border-radius: 27px;
  overflow: hidden;
}

@media print {
  .no-print {
    display: none;
  }

  body {
    transform: scale(0.99);
    transform-origin: top center;
  }
}

/* @page {
  size: landscape;
} */

table {
  width: 50%;
}

.form-container {
  min-width: 100%;
  border-radius: 27px;
}

.logo-bkoffice {
  font-family: "Outfit";
  font-size: 1.53rem;
  text-align: center;
  font-weight: 800;
  color: #202426;
  letter-spacing: 0.09rem;
}

.logo-bkoffice a {
  text-decoration: none;
  color: inherit;
}
